import { capitalizeWords, isEmpty, numberFormat } from '@touchstone/touchstone-assets';

export default {
    install(Vue) {
        if (this.installed) return;
        this.installed = true;

        Vue.filter('number_format', value => {
            return isEmpty(value) ? 'Not Available' : numberFormat(value);
        });

        Vue.filter('empty_string_format', value => {
            return isEmpty(value) ? 'Not Available' : value;
        });

        Vue.filter('capitalize_words_format', value => {
            return isEmpty(value) ? 'Not Available' : capitalizeWords(value);
        });
    }
};
